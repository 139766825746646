import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, Image } from "react-bootstrap"
import { Contract } from "web3-eth-contract"
import axios from "axios"
import defaultImage from "../../../images/candyrarerandom.gif"
import MintButton from "../../buttons/MintButton"

type MyCandiesProps = {
  accountAddress: string | null,
  contract: Contract,
  isTestNet: boolean,
  getAndSetAccount: Function
}

const MyCandies: React.FC<MyCandiesProps> = ({ accountAddress, contract, isTestNet, getAndSetAccount }) => {
  const [ownersCandiesMetadata, setOwnersCandiesMetadata] = useState<Object[]>([])
  const [contractAddress, setContractAddress] = useState<string>()

  useEffect(() => {
    if (accountAddress && contract) {
      contract.methods.listCandiesForOwner(accountAddress).call().then(async (ownersCandies: number[]) => {
        const ownersCandiesMetadata = await Promise.all(ownersCandies.map(async (tokenId) => {
          const tokenUri = await contract.methods.tokenURI(tokenId).call();
          if (tokenUri) {
            const { data: metaData } = await axios.get(tokenUri)
            return { ...metaData, tokenId }
          }
          return { tokenId }
        }))

        setOwnersCandiesMetadata(ownersCandiesMetadata);
        setContractAddress(contract.options.address);
      }).catch(e => {
        // TODO (Ben): Improve error handling
        console.log(e);
      })
    }
  }, [accountAddress, contract]);

  const candyCards = ownersCandiesMetadata.map((metadata, index) => {
    const { tokenId } = metadata
    // TODO (Ben): Use metadata to generate Opensea link instead of array index
    const openseaBaseUrl = isTestNet ? "https://testnets.opensea.io/assets/" : "https://opensea.io/assets/"
    const openseaUrl = openseaBaseUrl + contractAddress + "/" + tokenId
    const image = !!metadata && metadata["image"] ? metadata["image"] : defaultImage;
    // TODO (Ben): Use metadata to generate default candy title instead of array index
    const candyTitle = !!metadata && metadata["name"] ? metadata["name"] : "Crypto Candy #" + tokenId;

    return (
      <Col key={index} className="candy-card">
        <a href={openseaUrl} target="_blank" className="candy-card-link">
          <Card style={{ width: '18rem' }} className="no-border">
            <Card.Img variant="top" src={image} />
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>{candyTitle}</Card.Title>
            </Card.Body>
          </Card>
        </a>
      </Col>
    );
  });


  const header =
    candyCards.length > 0
      ? <>
        <Row>
          <Col>
            <h1>My Candies</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>🍬 {candyCards.length} {candyCards.length > 1 ? "CryptoCandies" : "CryptoCandy"} belong to {accountAddress} 🍬</h4>
          </Col>
        </Row>
      </>
      : <>
        <h1>How bland, you don't have any candies yet!</h1>
        <Image src={defaultImage} style={{
          justifyContent: "center",
          alignItems: "center",
          height: "400px",
          margin: 0,
          marginBottom: "1.45rem"
        }} />
        <br/>
        <p>You’ll be able to satisfy that sweet tooth soon 😉</p>
        {/* <p>You can change that very easily 😉</p> */}
        <MintButton accountAddress={accountAddress} contract={contract} getAndSetAccount={getAndSetAccount} />
      </>

  return (
    <>
      <Container>
        <div className="centered">{header}</div>
        <Row bsPrefix="row candy-cards-row">
          {candyCards}
        </Row>
      </Container>
    </>
  )
}

export default MyCandies
